import {NgModule}             from '@angular/core';
import {CommonModule}         from '@angular/common';
import {MatIconModule}        from '@angular/material/icon';
import {RouterModule}         from '@angular/router';
import {ProductUploadFilesComponent} from './product-upload-images.component';
import {SafePipe} from './safe-pipe';
import {NgbModule}            from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	          imports     : [RouterModule, CommonModule, NgbModule, MatIconModule],
	          declarations: [ProductUploadFilesComponent, SafePipe ],
	          exports     : [ProductUploadFilesComponent]
          })

export class ProductUploadImageModule{}
