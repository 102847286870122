import {Injectable}              from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable}              from 'rxjs';
import { environment } from 'environments/environment';

const baseUrl = environment.url;
@Injectable({
	            providedIn: 'root'
            })
export class StorageS{

	constructor(private http: HttpClient){ }

	getAll(module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchAll`);
	}

	getByType(module, type): Observable<any>{
		return this.http.get(`${baseUrl}${module}/fetchByType/${type}`);
	}


	login(data: object, module): Observable<any>{
		return this.http.post(`${baseUrl}${module}/adminLogin`, data);
	}

	get(id: number, module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/${id}`);
	}


	getByIds(data: [], module): Observable<any>{
		console.log(`${baseUrl}${module}/fetchByIds`);
		return this.http.post(`${baseUrl}${module}/fetchByIds`, data);
	}


	create(data: object, module): Observable<any>{
		return this.http.post(`${baseUrl}${module}/create`, data);
	}

	update(id: number, data: object, module): Observable<any>{
		return this.http.put(`${baseUrl}${module}/updateById/${id}`, data);
	}

	status_update(id: number, data: object, module): Observable<any>{
		return this.http.put(`${baseUrl}${module}/statusupdateById/${id}`, data);
	}

	updateRows(data: [], module): Observable<any>{
		return this.http.post(`${baseUrl}${module}/updateByIds`, data);
	}

	delete(id: number, module): Observable<any>{
		return this.http.delete(`${baseUrl}${module}/deleteById/${id}`);
	}

	deleteRows(ids: [], module): Observable<any>{
		return this.http.post(`${baseUrl}${module}/deleteByIds`, ids);
	}

	deleteAll(): Observable<any>{
		return this.http.delete(baseUrl);
	}

	findByTitle(title: string): Observable<any>{
		return this.http.get(`${baseUrl}?title=${title}`);
	}

	countForAdmin(module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/admin`);
	}

	countForVendor(id, module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/vendor/${id}`);
	}

	updatePassword(req: any, id): Observable<any>{
		return this.http.put(`${baseUrl}user/updatePassword/${id}`, req);
	}

	deleteFiles(models): Observable<any>{
		return this.http.post(`${baseUrl}files/delete`, models);
	}

	pushFileToStorage(file: File, module): Observable<any>{
		const formdata: FormData = new FormData();

		formdata.append('file', file);

		const req = new HttpRequest('POST', `${baseUrl}${module}/upload`, formdata, {
			reportProgress: true,
			responseType  : 'text'
		});

		return this.http.request(req);
	}

	pushVideoToStorage(file: File, module): Observable<any>{
		const formdata: FormData = new FormData();

		formdata.append('file', file);

		const req = new HttpRequest('POST', `${baseUrl}${module}/uploadVideo`, formdata, {
			reportProgress: true,
			responseType  : 'text'
		});
		return this.http.request(req);
	}

	pushBrochureToStorage(file: File, module): Observable<any>{
		const formdata: FormData = new FormData();

		formdata.append('file', file);

		const req = new HttpRequest('POST', `${baseUrl}${module}/uploadBrochure`, formdata, {
			reportProgress: true,
			responseType  : 'text'
		});
		return this.http.request(req);
	}

	pushBannerToStorage(file: File, type): Observable<any>{
		const formdata: FormData = new FormData();

		formdata.append('file', file);

		const req = new HttpRequest('POST', `${baseUrl}banner/upload/${type}`, formdata, {
			reportProgress: true,
			responseType  : 'text'
		});

		return this.http.request(req);
	}

	getFiles(module): Observable<any>{
		return this.http.get(`${baseUrl}${module}/file`);
	}

	getReportData(data: object): Observable<any>{
		return this.http.post(`${baseUrl}payment/generateReport`, data);
	}

  getByCustom(filter: object, module: string): Observable<any>{
		return this.http.post(`${baseUrl}${module}/fetchByCustom`, filter);
	}

	hasDuplicate(module, id, searchValue,attribute=0): Observable<any>{
		if(attribute){
			return this.http.get(`${baseUrl}${module}/hasDuplicate/${id}/${searchValue}/${attribute}`);
		}
		return this.http.get(`${baseUrl}${module}/hasDuplicate/${id}/${searchValue}`);
	}

	//file uploads
	/*upload(module, formData): Observable<any>{

		return this.http.post(`${baseUrl}${module}/upload`, formData);
	}

	getFile(): Observable<any>{
		return this.http.get(`${baseUrl}file`);
	}

	getFiles(): Observable<any>{
		return this.http.get(`${baseUrl}files`);
	}*/
}
