import {Routes} from '@angular/router';
import { AppGuard, AppGuardRole } from './app.guard';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';

export const AppRoutes: Routes = [ 
	
	{
		path     : 'login',
		loadChildren: ()=> import('./layouts/login/login.module').then(m=> m.LoginModule)
	},
	{
		path     : 'admin',
		component: AdminLayoutComponent,
		canActivate: [AppGuard],
		loadChildren: ()=> import('./layouts/admin-layout/admin-layout.module').then(m=> m.AdminLayoutModule)
	},
	{
		path     : 'vendor',
		component: AdminLayoutComponent,
		canActivate: [AppGuardRole],
		loadChildren: ()=> import('./layouts/vendor/vendor.module').then(m=> m.VendorModule)
	},
	{
		path      : '**',
		redirectTo: 'login'
	}
];
