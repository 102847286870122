import {HttpClientModule}        from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule}                from '@angular/core';
import {RouterModule}            from '@angular/router';
import {QuillModule}             from 'ngx-quill';
import {ToastrModule}            from 'ngx-toastr';

import {SidebarModule}     from './sidebar/sidebar.module';
import {FooterModule}      from './shared/footer/footer.module';
import {NavbarModule}      from './shared/navbar/navbar.module';
import {FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import {UploadImageModule} from './shared/upload/upload-image.module';
import {ProductUploadImageModule} from './shared/productupload/product-upload-image.module';

import {AppComponent} from './app.component';
import {AppRoutes}    from './app.routing';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';

import {Ng2SearchPipeModule} from 'ng2-search-filter';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';



@NgModule({
	          declarations: [
		          AppComponent,
		          AdminLayoutComponent

	          ],
	          imports     : [
		          BrowserAnimationsModule,
		          HttpClientModule,
		          RouterModule.forRoot(AppRoutes, {
			          useHash: true
		          }),
		          SidebarModule,
		          NavbarModule,
		          ToastrModule.forRoot(),
		          QuillModule.forRoot(),
		          FooterModule,
		          FixedPluginModule,
		          UploadImageModule,
              ProductUploadImageModule,
				  Ng2SearchPipeModule,
		          NgxUsefulSwiperModule
	          ],
	          providers   : [],
	          bootstrap   : [AppComponent]
          })
export class AppModule{}
