import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor(
      private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot){
    if(localStorage.getItem("AdminData")){
       let data = JSON.parse(localStorage.getItem("AdminData"));
       if(data.type==1){
         return true;
       } else{
         return false;
       }
    }
    else{
        this.router.navigateByUrl("/login");
        return false;
    }
  }

}

@Injectable({
  providedIn: 'root'
})

export class AppGuardRole implements CanActivate {

  constructor(
      private router: Router
  ) { }

  public canActivate(route: ActivatedRouteSnapshot){
    if(localStorage.getItem("AdminData")){
       let data = JSON.parse(localStorage.getItem("AdminData"));
       if(data.type==2){
         return true;
       } else{
         return false;
       }
    }
    else{
        this.router.navigateByUrl("/login");
        return false;
    }
  }

}